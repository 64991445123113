import { Card, Col, Row, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import StarRating from "./StarRating";
import profile from "../../../assets/images/profile.jpg";
import { useEffect, useState } from "react";
import { MdThumbUp, MdThumbDown } from "react-icons/md";
import axios from "../../../axios";
import { useToast } from "../../Toast";
import HeaderMenu from "../headerMenu";
import SideBar from "../SideBar";
import BreadCrumb from "../../plugins/Breadcrumb";
import RatingBar from "../../plugins/RatingBar";
import { BsShareFill } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { BiSolidCommentDetail } from "react-icons/bi";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";
import {
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import { useNavigate } from "react-router-dom";
import { revId } from "../../../Redux/Actions/userAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { GoDotFill } from "react-icons/go";
import { GrMail } from "react-icons/gr";
import { PiPhoneFill } from "react-icons/pi";
import { HiLocationMarker } from "react-icons/hi";
import { HiCake } from "react-icons/hi";
import { FiUsers, FiUser } from "react-icons/fi";
import Horizontalbar from "../../plugins/Horizontalbar";
import { IoIosArrowDown } from "react-icons/io";
import { Overlay, Popover, OverlayTrigger } from "react-bootstrap";
import { followId } from "../../../Redux/Actions/userAction";
import { BsQuestionDiamond } from "react-icons/bs";
import { ImTerminal } from "react-icons/im";
import { MdOutlineAttachment } from "react-icons/md";
import fileImg from "../../../assets/images/file.jpg";
import { ListGroupItem } from "reactstrap";
import mentionInputStyle from "../../../assets/css/MentionStyle";
import { MentionsInput, Mention } from "react-mentions";
import { AiFillFacebook } from "react-icons/ai";
import { FaSquareXTwitter, FaLinkedin } from "react-icons/fa6";
import InstagramIcon from "../../plugins/InstagramIcon";
import setldImg from "../../../assets/images/setld.jpg";
const FollowUser = (props) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const usrId = useSelector((state) => state.user.followid);
  const storedId = useSelector((state) => state.user.revId);
  const location = useLocation();
  const userIdMe = location.state?.userIdMe?.reviewedBy;
  const meData = location.state?.userIdMe;
  const userIdFollow = location.state?.userIdFollow;
  const userTypeFollow = location.state?.userTypeFollow;
  const profileId = location.state?.ProfileId;
  const userIdRev = location.state?.userIdRev;
  const reviewedByProfessional = location.state?.review?.reviewedByProfessional;
  const reviewedByBusiness = location.state?.review?.reviewedByBusiness;
  const professionalId = location.state?.review?.professionalId;
  const businessId = location.state?.review?.businessId;
  const reviewedBy = location.state?.review?.reviewedBy;
  const [userData, setuserData] = useState([]);
  const [reviewList, setReviewList] = useState([]);
  const [currentIndex, setcurrentIndex] = useState(null);
  const [showReport, setShowReport] = useState(false);
  const [reportMsg, setReportMsg] = useState("");
  const [reqId, setreqId] = useState("");
  const [reqType, setreqType] = useState("");
  const [reportCommentErr, setReportCommentErr] = useState("");
  const [isShare, setIsShare] = useState(false);
  const [comment, setComment] = useState("");
  const [childComment, setchildComment] = useState("");
  const [childcurrentIndex, setchildcurrentIndex] = useState(null);
  const [showReply, setShowReply] = useState(false);
  const [revCmnt, setRevCmnt] = useState([]);
  const [childrepplycurrentIndex, setchildreplycurrentIndex] = useState([]);
  const [showChildReply, setShowChildReply] = useState(false);
  const [revGlobalRtng, setRevGlobalRtng] = useState([]);
  const [avgCount, setAvgCount] = useState("");
  const [expandedCommentIndex, setExpandedCommentIndex] = useState(-1);
  const [replies, setRevReplies] = useState([]);
  const [showAttachment, setShowAttachment] = useState(false);
  const [attachment, setAttachment] = useState([]);
  const [ReviewSts, setReviewSts] = useState("");
  const [cmtError, setCmtError] = useState("");
  const [cmtErrId, setCmtErrId] = useState(null);
  const [userList, setUserList] = useState("");
  const [users, setUsers] = useState([]);
  const [mentionuserIds, setmentionuserIds] = useState([]);
  const [postBtnDisable, setPostBtnDisable] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    viewUserdetails();
    loadReviewList();
    additionalReviewList();
    if (userIdFollow || userIdMe || userIdRev) {
      dispatch(revId(userIdRev));
      setreqId(
        userIdFollow
          ? userIdFollow
          : userIdMe
            ? userIdMe
            : userIdRev
              ? userIdRev
              : storedId
      );
      setreqType(userTypeFollow ? userTypeFollow : userTypeFollow);
    }
    userLists();
  }, []);
  const userLists = async () => {
    try {
      const response = await window.apiService.lookupAllUsersList();
      const usersArr = response.data.recordInfo.filter((row) => {
        return (
          row.userId != props.userId &&
          row.userId.split("-")[0] != props.userId &&
          row.userId.split("&")[0] != props.userId
        );
      });
      const users = usersArr.map(function (row) {
        return { display: row.name, id: row.userId };
      });
      setUsers(users);
    } catch (error) {
      console.log(error);
    }
  };

  const getRevQnsRating = (value) => {
    const num =
      value.reduce((total, next) => total + next.rating, 0) / value.length;
    return num.toFixed(1);
  };
  const changeCmtText = (value, type) => {
    var words = value.split(/\s(?![^\[]*])/);
    var contents = words.map(function (word, i) {
      var separator = i < words.length - 1 ? " " : "";
      return word + separator;
    });
    if (contents.length != 0) {
      var filtercontents = contents.filter((name) => name.startsWith("@"));
      var missing = filtercontents.map(function (o1) {
        if (o1 != "@" && o1 && o1.includes("](")) {
          var mention = o1.split("](");
          var men_id = mention[1].replace(")", "");
          if (men_id.includes("-")) {
            var usrId = men_id.split("-");
            var userId = usrId[0];
            var ProfId = usrId[1];
            return { userId: +userId, professionalId: +ProfId };
          } else if (men_id.includes("&")) {
            var usrId = men_id.split("&");
            var userId = usrId[0];
            var BusnId = usrId[1];
            return { userId: +userId, businessId: +BusnId };
          } else return { userId: +men_id, professionalId: null };
        }
      });
      if (missing !== undefined) {
        const filteredMissing = missing.filter((item) => item !== undefined);
        const uniqueData = Array.from(
          new Set(filteredMissing.map(JSON.stringify))
        ).map(JSON.parse);
        setmentionuserIds(uniqueData);
      }
    }
    let cmtText = contents.reduce(
      (accumulator, item) => (accumulator += item),
      ""
    );
    if (type == "parent") {
      if (cmtText.length > 288) {
        var limit_char = cmtText.substring(0, 288);
        setComment(limit_char);
      } else setComment(cmtText);
    } else {
      if (cmtText.length > 288) {
        var limit_char = cmtText.substring(0, 288);
        setchildComment(limit_char);
      } else setchildComment(cmtText);
    }
  };
  const handleMention = (mentionedUser) => {
    const match = mentionedUser.match(/@\[([^\]]+)\](?:\(([^)]+)\))?/);
    if (match) {
      const [, name, userIdPart] = match;
      let userId = userIdPart;
      let busnId = null;
      let profId = null;
      if (userIdPart && userIdPart.includes("&")) {
        [userId, busnId] = userIdPart.split("&");
      } else if (userIdPart && userIdPart.includes("-")) {
        [userId, profId] = userIdPart.split("-");
      }
      if (name && userId) {
        if (userId !== props.userId) {
          dispatch(followId(userId));
          navigate("/dashboard/viewuser", {
            state: {
              userId,
              userType:
                profId || busnId
                  ? profId
                    ? "Professional"
                    : "Business"
                  : "Personal",
              profileId: profId || busnId ? (profId ? profId : busnId) : null,
            },
          });
        }
      }
    }
  };

  const rmvMenFormat = (value) => {
    const mentionRegex = /@\[([^\]]+?)\](?:\(\d+([&-])\d+\))|@([^\s]+)/g;
    const match = mentionRegex.exec(value);
    if (match) {
      if (match[3]) {
        const nestedMatch = match[3].match(/\[([^\]]+)\]\(\d+\)/);
        return nestedMatch ? nestedMatch[1] : "";
      }
      return match[1] || "";
    }
    return null;
  };
  function textWithLinks(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const mentionRegex = /@\[([^\]]+?)\](\((\d+([&-])\d+)?\))|@([^\s]+)/g;
    return text.split(/\s(?![^\[]*])/).map((word, index) => {
      if (urlRegex.test(word)) {
        return (
          <a key={index} href={word} target="_blank" rel="noopener noreferrer">
            {word}
          </a>
        );
      } else if (mentionRegex.test(word)) {
        const mentions = word.match(mentionRegex);
        if (mentions) {
          return mentions.map((mention, mentionIndex) => {
            const match = mention.match(/@\[([^\]]+?)\](\((\d+([&-])\d+)?\))/g);
            const id = match ? match[2] : null;
            return (
              <span
                key={index + mentionIndex}
                className={id == props.userId ? "" : "cmt_icon c_pointer"}
                onClick={() => handleMention(mention)}
              >
                {rmvMenFormat(mention)}
              </span>
            );
          });
        }
      }

      return word;
    });
  }
  const checkExt = (file) => {
    let allowedfiletypes = ["jpg", "svg", "jpeg", "png", "bmp", "gif", "jfif"];
    if (
      allowedfiletypes.some(
        (ext) =>
          ext == file.fileName.substring(file.fileName.lastIndexOf(".") + 1)
      )
    ) {
      return true;
    }
    return false;
  };
  const expendComment = (index) => {
    setExpandedCommentIndex(index === expandedCommentIndex ? -1 : index);
  };
  const handleEvent = (review) => {
    if (userIdRev || storedId) {
      navigate("/dashboard/reviews/more/view", { state: { review } });
    }
  };
  const handleNameClick = (userId, userType, TypeId) => {
    dispatch(followId(userId, userType, TypeId));
    navigate("/dashboard/viewuser", {
      state: { userId: userId, userType: userType, profileId: TypeId },
    });
  };
  const togglecomment = (id) => {
    setComment("");
    setchildcurrentIndex(null);
    setShowChildReply(false);
    if (currentIndex == id) {
      setcurrentIndex(null);
    } else {
      setcurrentIndex(id);
    }
  };
  const togglechildcomment = (id) => {
    setchildComment("");
    if (childrepplycurrentIndex == id) {
      setchildreplycurrentIndex(null);
    } else {
      setchildreplycurrentIndex(id);
    }
  };
  const viewUserdetails = async () => {
    if (userIdFollow || userIdMe || userIdRev || storedId) {
      const params = {
        id: userIdFollow
          ? userIdFollow
          : userIdMe
            ? userIdMe
            : userIdRev
              ? userIdRev
              : storedId,
        userType: userTypeFollow
          ? userTypeFollow
          : reviewedBy &&
            (reviewedByProfessional == undefined || professionalId == undefined)
            ? "Personal"
            : reviewedBy &&
              (reviewedByProfessional != undefined || professionalId != undefined)
              ? "Professional"
              : "",
        profileId:
          userTypeFollow != "Personal"
            ? profileId
              ? profileId
              : professionalId
                ? professionalId
                : ""
            : undefined,
      };
      try {
        const response = await window.apiService.userProfileView(params);
        setuserData(response.data.data);
      } catch (error) {
        toast.error("Error Occured");
      }
    }
  };

  const loadReviewList = async () => {
    if (userIdMe) {
      var userIdLog = props.userId;
      const params = {
        userId: userIdMe ? userIdMe : storedId,
        userType: props.userType,
        profileId: props.userType != "Personal" ? props.profileId : undefined,
      };
      try {
        const response = await window.apiService.getReviewListById(params);
        if (response.data.status === "OK") {
          const filteredResult = response.data.recordInfo.filter((item) => {
            const isMatch = item.userId === userIdLog || item.userId === usrId;
            return isMatch;
          });
          setReviewList(filteredResult);
          console.log("filteredResult", filteredResult);
        }
      } catch (error) {
        console.log(error);
        toast.error("Error Occured");
      }
    }
  };
  const postComment = async (id, revId, type) => {
    if (comment.trim().length !== 0 || childComment.trim().length !== 0) {
      let params = "";
      if (type == "child") {
        params = {
          userId: props.userId,
          commentText: childComment,
          reviewId: revId,
          parentCommentId: id,
          mentionUserIds: mentionuserIds,
          professionalId:
            props.userType == "Professional" ? props.profileId : null,
          businessId: props.userType == "Business" ? props.profileId : null,
        };
      } else {
        params = {
          userId: props.userId,
          commentText: comment,
          reviewId: id,
          parentCommentId: null,
          mentionUserIds: mentionuserIds,
          professionalId:
            props.userType == "Professional" ? props.profileId : null,
          businessId: props.userType == "Business" ? props.profileId : null,
        };
      }
      setPostBtnDisable(true);
      try {
        const response = await window.apiService.addComment(params);
        if (response.data.status == "OK") {
          toast.success(response.data.message);
          setComment("");
          setchildComment("");
          togglecomment(response.data.id);
          setmentionuserIds([]);
          if (userIdMe) {
            loadReviewList(reqId);
          } else {
            additionalReviewList(reqId);
          }
          loadRevComments(params.reviewId);
          setPostBtnDisable(false);
        }
      } catch (error) {
        console.log(error);
        toast.error("Error Occured");
        setPostBtnDisable(false);
      }
    } else {
      setCmtError("Comment text is required");
      setCmtErrId(id);
    }
  };
  const handleReport = (event) => {
    setReportMsg(event.target.value);
    if (event.target.value) {
      setReportCommentErr("");
    }
  };
  const additionalReviewList = async (id) => {
    if (userIdRev || userIdFollow || id || storedId) {
      const params = {
        userId: userIdFollow
          ? userIdFollow
          : id
            ? id
            : userIdRev
              ? userIdRev
              : storedId,
        userType: props.userType,
        profileId: props.userType != "Personal" ? props.profileId : undefined,
      };
      try {
        const response = await window.apiService.getReviewListByUserId(params);
        if (response.data.status == "OK") {
          if (userIdRev || storedId) {
            if (
              reviewedBy != null &&
              professionalId == undefined &&
              reviewedByProfessional == undefined &&
              reviewedByBusiness == undefined &&
              businessId == undefined
            ) {
              const filteredList = response.data.recordInfo.filter(
                (item) =>
                  item.reviewedBy === item.loggedInUserId &&
                  item.professionalId == undefined &&
                  item.reviewedByProfessional == undefined &&
                  item.reviewedByBusiness == undefined &&
                  item.businessId == undefined
              );
              setReviewList(filteredList);
            } else if (
              reviewedBy != null &&
              professionalId != undefined &&
              reviewedByProfessional == undefined &&
              reviewedByBusiness == undefined
            ) {
              const filteredList = response.data.recordInfo.filter(
                (item) =>
                  item.reviewedBy === item.loggedInUserId &&
                  item.professionalId == professionalId &&
                  item.reviewedByProfessional == undefined &&
                  item.reviewedByBusiness == undefined
              );
              setReviewList(filteredList);
            } else if (
              reviewedBy != null &&
              businessId != undefined &&
              reviewedByProfessional == undefined &&
              reviewedByBusiness == undefined
            ) {
              const filteredList = response.data.recordInfo.filter(
                (item) =>
                  item.reviewedBy === item.loggedInUserId &&
                  item.businessId == businessId &&
                  item.reviewedByProfessional == undefined &&
                  item.reviewedByBusiness == undefined
              );
              setReviewList(filteredList);
            } else if (
              reviewedBy != null &&
              professionalId == undefined &&
              reviewedByProfessional != undefined &&
              reviewedByBusiness == undefined &&
              businessId == undefined
            ) {
              const filteredList = response.data.recordInfo.filter(
                (item) =>
                  item.reviewedBy === item.loggedInUserId &&
                  item.professionalId == undefined &&
                  item.reviewedByProfessional == reviewedByProfessional &&
                  item.reviewedByBusiness == undefined &&
                  item.businessId == undefined
              );
              setReviewList(filteredList);
            } else if (
              reviewedBy != null &&
              professionalId != undefined &&
              reviewedByProfessional != undefined &&
              reviewedByBusiness == undefined &&
              businessId == undefined
            ) {
              const filteredList = response.data.recordInfo.filter(
                (item) =>
                  item.reviewedBy === item.loggedInUserId &&
                  item.professionalId == professionalId &&
                  item.reviewedByProfessional == reviewedByProfessional &&
                  item.reviewedByBusiness == undefined &&
                  item.businessId == undefined
              );
              setReviewList(filteredList);
            } else if (
              reviewedBy != null &&
              businessId != undefined &&
              reviewedByProfessional == undefined &&
              reviewedByBusiness != undefined &&
              professionalId == undefined
            ) {
              const filteredList = response.data.recordInfo.filter(
                (item) =>
                  item.reviewedBy === item.loggedInUserId &&
                  item.professionalId == undefined &&
                  item.reviewedByProfessional == undefined &&
                  item.reviewedByBusiness == reviewedByBusiness &&
                  item.businessId == businessId
              );
              setReviewList(filteredList);
            } else if (
              reviewedBy != null &&
              professionalId == undefined &&
              reviewedByProfessional == undefined &&
              reviewedByBusiness != undefined &&
              businessId == undefined
            ) {
              const filteredList = response.data.recordInfo.filter(
                (item) =>
                  item.reviewedBy === item.loggedInUserId &&
                  item.professionalId == undefined &&
                  item.reviewedByProfessional == undefined &&
                  item.reviewedByBusiness == reviewedByBusiness &&
                  item.businessId == undefined
              );
              setReviewList(filteredList);
            } else if (
              reviewedBy != null &&
              professionalId != undefined &&
              reviewedByProfessional == undefined &&
              reviewedByBusiness != undefined &&
              businessId == undefined
            ) {
              const filteredList = response.data.recordInfo.filter(
                (item) =>
                  item.reviewedBy === item.loggedInUserId &&
                  item.professionalId == professionalId &&
                  item.reviewedByProfessional == undefined &&
                  item.reviewedByBusiness == reviewedByBusiness &&
                  item.businessId == undefined
              );
              setReviewList(filteredList);
            } else if (
              reviewedBy != null &&
              businessId != undefined &&
              reviewedByProfessional != undefined &&
              reviewedByBusiness == undefined &&
              professionalId == undefined
            ) {
              const filteredList = response.data.recordInfo.filter(
                (item) =>
                  item.reviewedBy === item.loggedInUserId &&
                  item.professionalId == undefined &&
                  item.reviewedByProfessional == reviewedByProfessional &&
                  item.reviewedByBusiness == undefined &&
                  item.businessId == businessId
              );
              setReviewList(filteredList);
            }
          } else {
            let result = response.data.recordInfo;
            setReviewList(result);
          }
        }
      } catch (error) {
        console.log(error);
        toast.error("Error Occured");
      }
    }
  };
  const sendReport = () => {
    setShowReport(true);
  };
  const submitReport = async () => {
    if (reportMsg) {
      if (meData) {
        try {
          const params = {
            reviewId: meData.id,
            reporterId: props.userId,
            additionalInfo: reportMsg,
          };
          const response = await window.apiService.addReport(params);
          if (response.data.status == "OK") {
            toast.success("Report added successfully");
            setShowReport(false);
            viewUserdetails();
            if (userIdMe) {
              loadReviewList(reqId);
            } else {
              additionalReviewList(reqId);
            }
          }
        } catch (error) {
          toast.error("Error Occured");
        }
      }
    } else {
      setReportCommentErr("Report Comment is required.");
    }
  };
  const getSocialMediaShareInfo = () => {
    // return "Recruitment is going on for Click the link to Find Jobs and Get Hired";
  };
  const getSocialMediaHashTag = (title, code) => {
    // return "hzzshhdz";
  };
  const handleShare = () => {
    setIsShare(true);
  };
  const handlelikechange = (id) => {
    updateLikeStatus({ reviewId: id, userId: props.userId, liked: 1 });
  };
  const handledislikechange = (id) => {
    updateLikeStatus({ reviewId: id, userId: props.userId, liked: 0 });
  };
  const handlerplylikechange = (id, revId) => {
    console.log("revId", revId);
    updaterplyLikeStatus({
      reviewId: revId,
      commentId: id,
      userId: props.userId,
      liked: 1,
    });
  };
  const handlerplydislikechange = (id, revId) => {
    updaterplyLikeStatus({
      reviewId: revId,
      commentId: id,
      userId: props.userId,
      liked: 0,
    });
  };
  const updaterplyLikeStatus = async (likeParams) => {
    try {
      const response = await window.apiService.addCommentLike(likeParams);
      if (response.data.status == "OK") {
        if (userIdMe) {
          loadReviewList(reqId);
        } else {
          additionalReviewList(reqId);
        }
        loadRevComments(likeParams.reviewId);
      }
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  const updateLikeStatus = async (likeParams) => {
    try {
      const response = await window.apiService.addLike(likeParams);
      if (response.data.status == "OK") {
        if (userIdMe) {
          loadReviewList(reqId);
        } else {
          additionalReviewList(reqId);
        }
      }
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  const likecntDecrement = (id) => {
    updateLikeStatus({ reviewId: id, userId: props.userId, liked: -1 });
  };
  const rplylikecntDecrement = (id, revId) => {
    updaterplyLikeStatus({
      reviewId: revId,
      commentId: id,
      userId: props.userId,
      liked: -1,
    });
  };
  const viewRevComments = (data, replies, sts) => {
    loadRevComments(data[0].reviewId);
    setShowReply(!showReply);
    setReviewSts(sts);
    setShowChildReply(false);
    setcurrentIndex(null);
    setchildreplycurrentIndex(null);
  };
  const loadRevComments = async (revId) => {
    try {
      const response = await window.apiService.reviewCmtList({ id: revId });
      setRevCmnt(response.data.recordInfo.reviewComments);
      setRevReplies(response.data.recordInfo.reviewReplies);
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  const viewChildComments = (data, id, revId) => {
    setcurrentIndex(null);
    setShowChildReply(!showChildReply);
    if (childcurrentIndex == id) {
      setchildcurrentIndex(null);
    } else {
      setchildcurrentIndex(id);
    }
    loadRevComments(revId);
  };
  const handleArrowClick = (rating, avgCount) => {
    setAvgCount(avgCount);
    const starResponse = rating;
    const starData = starResponse[0];

    const labelValueMap = {};
    for (const label in starData) {
      labelValueMap[label] = parseFloat(starData[label]);
    }
    const sortedLabels = Object.keys(labelValueMap).sort((a, b) => {
      const numA = parseInt(a.split(" ")[0]);
      const numB = parseInt(b.split(" ")[0]);
      return numB - numA;
    });
    const result = sortedLabels.map((label) => ({
      label,
      value: labelValueMap[label],
    }));
    setRevGlobalRtng(result);
  };
  const viewAttachment = (data) => {
    setShowAttachment(true);
    setAttachment(data);
    console.log("sdfsdfs", data);
  };

  return (
    <>
      <HeaderMenu />
      <div className="">
        <SideBar />
        <div className="main-content ml_200">
          <BreadCrumb />
          <Card className="shade h-500 card">
            <Col lg={12}>
              <Row>
                <Col lg={4}>
                  <div className="review-container m-3">
                    <Row>
                      <Col lg={6} md={6} className="text-center">
                        <img
                          className="following_img"
                          src={
                            userData.userType == "Professional"
                              ? userData.professional.imagePath
                                ? userData.professional.imagePath
                                : profile
                              : userData.imagePath
                                ? userData.imagePath
                                : profile
                          }
                        />
                        <h6
                          className="follUser"
                          style={{ marginBottom: "0" }}
                          onClick={() =>
                            handleNameClick(
                              userData.id,
                              userData.userType,
                              userData.userType == "Professional"
                                ? userData.professional.id
                                : null
                            )
                          }
                        >
                          {userData.name}&nbsp;
                          {userData.userType == "Professional"
                            ? "(" + userData.professional.professionType + ")"
                            : ""}
                          &nbsp;
                          {userData.userType == "Business"
                            ? "(" + userData.business.companyName + ")"
                            : ""}
                        </h6>
                        <div className="d-flex align-items-center justify-content-center">
                          {userData.userType == "Professional" ? (
                            userData.professional.averageReviewScore > 0.0 ? (
                              <label className="font-weight-bold total_fnt mar-10">
                                {userData.professional.averageReviewScore.toFixed(
                                  1
                                )}
                              </label>
                            ) : (
                              ""
                            )
                          ) : userData.averageReviewScore > 0.0 ? (
                            <label className="font-weight-bold total_fnt mar-10">
                              {userData.averageReviewScore.toFixed(1)}
                            </label>
                          ) : (
                            ""
                          )}
                          <StarRating
                            rating={
                              userData.userType == "Professional"
                                ? userData.professional.averageReviewCount
                                : userData.averageReviewCount
                            }
                            disabled={true}
                            customClass="user-star"
                          />
                          <span className="fs_12">
                            (
                            {userData.userType == "Professional"
                              ? userData.professional.totalReviewCount
                              : userData.totalReviewCount}
                            )
                          </span>
                          <OverlayTrigger
                            trigger="click"
                            key={userData.id}
                            placement="bottom"
                            overlay={
                              <Popover
                                id={`popover-${userData.id}`}
                                className="pop_rating"
                              >
                                <div className="drp_rating">
                                  <StarRating
                                    rating={avgCount}
                                    disabled={true}
                                  />
                                  <span>{avgCount} out of 5</span>{" "}
                                </div>
                                {revGlobalRtng.map((item, index) => (
                                  <Row key={index}>
                                    <Col
                                      sm={12}
                                      md={3}
                                      lg={3}
                                      xl={2}
                                      className="p-0"
                                    >
                                      <span className="prog_span">
                                        {item.label}
                                      </span>{" "}
                                    </Col>
                                    <Col
                                      sm={12}
                                      md={8}
                                      lg={8}
                                      xl={7}
                                      className="p-0"
                                    >
                                      <Horizontalbar dataSet={item.value} />
                                    </Col>
                                    <Col
                                      sm={12}
                                      md={3}
                                      lg={3}
                                      xl={2}
                                      className="p-0 prog_span"
                                      style={{ marginLeft: "10px" }}
                                    >
                                      <span>
                                        {item.value}
                                        {item.value > 1 ? (
                                          <FiUsers
                                            style={{ marginLeft: "6px" }}
                                          />
                                        ) : (
                                          <FiUser
                                            style={{ marginLeft: "6px" }}
                                          />
                                        )}
                                      </span>
                                    </Col>
                                  </Row>
                                ))}
                              </Popover>
                            }
                            rootClose
                          >
                            <span
                              className="d-flex align-items-center"
                              onClick={() =>
                                handleArrowClick(
                                  userData.userType == "Professional"
                                    ? userData.professional.starRating
                                    : userData.starRating,
                                  userData.userType == "Professional"
                                    ? userData.professional.averageReviewCount
                                    : userData.averageReviewCount
                                )
                              }
                            >
                              <IoIosArrowDown className="clr_gray" />
                            </span>
                          </OverlayTrigger>{" "}
                        </div>
                      </Col>
                      {/* <Col lg={7} md={7} style={{paddingTop:'5%'}}>
                    <h6>{userData.name}</h6>
                    </Col> */}
                    </Row>
                    <div className="row d-flex">
                      <div className="col-6 col-md-6 col-lg-4 reviewPage">
                        <div className="text-center circle-background ">
                          <span>
                            {userData.userType == "Professional"
                              ? userData.professional.professionalFollowerCount
                              : userData.followersCount}
                          </span>
                        </div>
                        <div className="clr_follow">Followers</div>
                      </div>
                      <div className="col-6 col-md-6 col-lg-4 reviewPage">
                        <div className="text-center circle-background">
                          <span>
                            {userData.userType == "Professional"
                              ? userData.professional.professionalFollowingCount
                              : userData.followingCount}
                          </span>
                        </div>
                        <div className="clr_follow">Following</div>
                      </div>
                    </div>
                    {false && (
                      <div className="row d-flex">
                        <div className="col-3 col-md-5 col-lg-3 reviewPage dt">
                          <h6>Name</h6>
                        </div>
                        <div className="col-9 col-md-6 col-lg-9 reviewPage">
                          {userData.name}
                        </div>
                      </div>
                    )}
                    {userData.email && (
                      <div className="row d-flex">
                        <div className="col-2 col-md-2 col-lg-1 reviewPage pad_t_b">
                          <h6>
                            <GrMail />
                          </h6>
                        </div>
                        <div className="col-9 col-md-6 col-lg-10 reviewPage pad_t_b">
                          {userData.isPublic
                            ? userData.email
                            : userData.email.slice(0, 4) +
                            "x".repeat(
                              Math.max(0, userData.email.length - 11)
                            )}
                        </div>
                      </div>
                    )}
                    {userData.userType == "Professional" ? (
                      userData.professional?.officePhone ? (
                        <div className="row d-flex">
                          <div className="col-2 col-md-2 col-lg-1 reviewPage pad_t_b">
                            <h6>
                              <PiPhoneFill />
                            </h6>
                          </div>
                          <div className="col-9 col-md-6 col-lg-9 reviewPage pad_t_b">
                            {userData.isPublic
                              ? userData.professional?.officePhone
                              : "x".repeat(
                                Math.max(
                                  0,
                                  userData.professional?.officePhone.length -
                                  4
                                )
                              ) +
                              userData.professional?.officePhone.slice(-4)}
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                    ) : userData.phone ? (
                      <div className="row d-flex">
                        <div className="col-2 col-md-2 col-lg-1 reviewPage pad_t_b">
                          <h6>
                            <PiPhoneFill />
                          </h6>
                        </div>
                        <div className="col-9 col-md-6 col-lg-9 reviewPage pad_t_b">
                          {userData.isPublic
                            ? userData.phone
                            : "x".repeat(
                              Math.max(0, userData.phone.length - 4)
                            ) + userData.phone.slice(-4)}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {userData.userType == "Professional" ? (
                      userData.professional.location ? (
                        <div className="row d-flex">
                          <div className="col-2 col-md-2 col-lg-1 reviewPage pad_t_b">
                            <h6>
                              <HiLocationMarker />
                            </h6>
                          </div>
                          <div className="col-9 col-md-6 col-lg-9 reviewPage pad_t_b">
                            {userData.professional.location}
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                    ) : userData.city || userData.state ? (
                      <div className="row d-flex">
                        <div className="col-2 col-md-2 col-lg-1 reviewPage pad_t_b">
                          <h6>
                            <HiLocationMarker />
                          </h6>
                        </div>
                        <div className="col-9 col-md-6 col-lg-9 reviewPage pad_t_b">
                          {userData.city} {userData.state}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* {userData.state?<div className="row d-flex">
                <div className="col-2 col-md-3 col-lg-1 reviewPage"><h6>State</h6></div>
                <div className="col-9 col-md-6 col-lg-9 reviewPage">{userData.state}</div>
                </div>:''} */}
                    {userData.birthYear ? (
                      <div className="row d-flex">
                        <div className="col-2 col-md-2 col-lg-1 reviewPage">
                          <h6>
                            <HiCake />
                          </h6>
                        </div>
                        <div className="col-9 col-md-6 col-lg-9 reviewPage">
                          {userData.birthYear}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {userData.isPublic && (
                      <div className="mt-2">
                        {userData.facebookUrl && (
                          <a
                            href={userData.facebookUrl}
                            target="_blank"
                            className="a_social"
                          >
                            <AiFillFacebook
                              className="c_pointer social_icon_f mr-2"
                              size={28}
                            />
                          </a>
                        )}
                        {userData.linkedinUrl && (
                          <a
                            href={userData.linkedinUrl}
                            target="_blank"
                            className="a_social"
                          >
                            <FaLinkedin
                              size={25}
                              className="social_icon_li mr-2"
                            />
                          </a>
                        )}
                        {userData.instagramUrl && (
                          <a
                            href={userData.instagramUrl}
                            target="_blank"
                            className="a_social mr-2"
                          >
                            <InstagramIcon />
                          </a>
                        )}
                        {userData.twitterUrl && (
                          <a
                            href={userData.twitterUrl}
                            target="_blank"
                            className="a_social"
                          >
                            <FaSquareXTwitter
                              size={25}
                              className="rounded-0 social_icon mr-2"
                            />
                          </a>
                        )}
                        {userData.facebookUrl ||
                          userData.linkedinUrl ||
                          userData.instagramUrl ||
                          userData.twitterUrl ? (
                          <a
                            href="https://setld.app/"
                            target="_blank"
                            className="a_social mr-2"
                          >
                            <img src={setldImg} height={22} width={22} />
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                </Col>
                <Col lg={8} className="h_75">
                  <div
                    className={
                      reviewList != "" && reviewList != undefined
                        ? "fv_crd_data"
                        : "fv_crd"
                    }
                  >
                    {reviewList &&
                      reviewList.map((list, i) => (
                        <Card
                          className={`${list.status === "reported" ||
                            list.status == "blocked"
                            ? "p-3 bg_gray rev_padg mb_3"
                            : "p-3 mb_3"
                            }`}
                        >
                          {(list.status == "reported" ||
                            list.status == "blocked") && (
                              <div className="text-end dot_3">
                                {list.status == "reported" ? (
                                  <span className="uder_Rev">Under Review</span>
                                ) : list.status == "blocked" ? (
                                  <span className="uder_Rev">blocked</span>
                                ) : (
                                  ""
                                )}
                              </div>
                            )}
                          <Row>
                            <Col lg={8}>
                              <Row>
                                <Col lg={4} className="card_border">
                                  <img
                                    className="brd_rad"
                                    width="30"
                                    height="30"
                                    src={
                                      list.reviewerImagePath
                                        ? list.reviewerImagePath
                                        : profile
                                    }
                                  />
                                  {list.status != "reported" &&
                                    list.status != "blocked" ? (
                                    <div
                                      className="follUser fz_14"
                                      onClick={() =>
                                        handleNameClick(
                                          list.reviewedBy,
                                          list.reviewedByProfessional !=
                                            undefined
                                            ? "Professional"
                                            : list.reviewedBy &&
                                              list.reviewdByProfessional ==
                                              undefined &&
                                              list.reviewedByBusiness ==
                                              undefined
                                              ? "Personal"
                                              : "",
                                          list.reviewedByProfessional
                                        )
                                      }
                                    >
                                      {list.reviewerName}
                                    </div>
                                  ) : (
                                    <div className="clr_gray fz_14">
                                      {list.reviewerName}
                                    </div>
                                  )}
                                  <div className="d-flex align-items-center">
                                    {list.averageReviewScore > 0.0 ? (
                                      <label className="font-weight-bold total_fnt mar-10">
                                        {list.averageReviewScore.toFixed(1)}
                                      </label>
                                    ) : (
                                      ""
                                    )}
                                    <StarRating
                                      isReview={true}
                                      rating={list.averageReviewCount}
                                      disabled={true}
                                    />
                                    <span className="fs_12">
                                      ({list.totalReviewCount})
                                    </span>
                                    <OverlayTrigger
                                      trigger="click"
                                      key={list.id}
                                      placement="bottom"
                                      overlay={
                                        <Popover
                                          id={`popover-${list.id}`}
                                          className="pop_rating"
                                        >
                                          <div className="drp_rating">
                                            <StarRating
                                              rating={avgCount}
                                              disabled={true}
                                            />
                                            <span>{avgCount} out of 5</span>{" "}
                                          </div>
                                          {revGlobalRtng.map((item, index) => (
                                            <Row key={index}>
                                              <Col
                                                sm={12}
                                                md={3}
                                                lg={3}
                                                xl={2}
                                                className="p-0"
                                              >
                                                <span className="prog_span">
                                                  {item.label}
                                                </span>{" "}
                                              </Col>
                                              <Col
                                                sm={12}
                                                md={8}
                                                lg={8}
                                                xl={7}
                                                className="p-0"
                                              >
                                                <Horizontalbar
                                                  dataSet={item.value}
                                                />
                                              </Col>
                                              <Col
                                                sm={12}
                                                md={3}
                                                lg={3}
                                                xl={2}
                                                className="p-0 prog_span"
                                                style={{ marginLeft: "10px" }}
                                              >
                                                <span>
                                                  {item.value}
                                                  {item.value > 1 ? (
                                                    <FiUsers
                                                      style={{
                                                        marginLeft: "6px",
                                                      }}
                                                    />
                                                  ) : (
                                                    <FiUser
                                                      style={{
                                                        marginLeft: "6px",
                                                      }}
                                                    />
                                                  )}
                                                </span>
                                              </Col>
                                            </Row>
                                          ))}
                                        </Popover>
                                      }
                                      rootClose
                                    >
                                      <span
                                        className="d-flex align-items-center"
                                        onClick={() =>
                                          handleArrowClick(
                                            list.starRating,
                                            list.averageReviewCount
                                          )
                                        }
                                      >
                                        <IoIosArrowDown className="clr_gray" />
                                      </span>
                                    </OverlayTrigger>
                                  </div>
                                </Col>
                                {/* <Col lg={3}>
                                    <p className='follUser' onClick={()=>handleNameClick(list.reviewedBy)}>{list.reviewerName}</p>
                                </Col> */}
                                {/* <Col lg={5}>
                                    <StarRating isReview={true} rating={list.rating} disabled={true}/>
                                </Col> */}
                                {meData ? (
                                  <Col lg={8} className="text-end">
                                    <Row className="d-flex text-start">
                                      <Col lg={7}>
                                        <div
                                          className={` ${expandedCommentIndex === i
                                            ? "show-full"
                                            : ""
                                            }`}
                                          title={list.comment}
                                        >
                                          {list.comment.length >= 30 &&
                                            expandedCommentIndex !== i
                                            ? textWithLinks(
                                              list.comment.substring(0, 30)
                                            )
                                              .map((word, index) => (
                                                <span key={index}>
                                                  {word}{" "}
                                                </span>
                                              ))
                                              .concat("...")
                                            : textWithLinks(list.comment).map(
                                              (word, index) => (
                                                <span key={index}>
                                                  {word}{" "}
                                                </span>
                                              )
                                            )}
                                        </div>

                                        {list.comment.length >= 30 && (
                                          <button
                                            onClick={() => expendComment(i)}
                                            className="read-more-button"
                                          >
                                            {expandedCommentIndex === i
                                              ? "Read less"
                                              : "Read more"}
                                          </button>
                                        )}
                                        <div className="d-flex">
                                          {list.reviewComments.length != 0 && (
                                            <span
                                              className="rply c_pointer"
                                              onClick={() =>
                                                viewRevComments(
                                                  list.reviewComments,
                                                  list.reviewReplies,
                                                  list.status
                                                )
                                              }
                                            >
                                              {list.reviewComments.length === 1
                                                ? `${list.reviewComments.length} Reply `
                                                : `${list.reviewComments.length} Replies `}
                                            </span>
                                          )}
                                          {(meData &&
                                            list.status == "reported" &&
                                            !userIdRev &&
                                            !storedId) ||
                                            list.reviewedBy ===
                                            list.loggedInUserId ||
                                            list.reviewedBy === props.userId ? (
                                            <BiSolidCommentDetail
                                              className="cmt_icon clr_gray mt-1"
                                              title="Add comment"
                                            />
                                          ) : userIdRev || storedId ? (
                                            ""
                                          ) : (
                                            <BiSolidCommentDetail
                                              className="cmt_icon c_pointer mt-1"
                                              title="Add comment"
                                              onClick={() =>
                                                togglecomment(list.id)
                                              }
                                            />
                                          )}
                                        </div>
                                      </Col>
                                      <Col lg={5} style={{ padding: "0" }}>
                                        <div className="d-flex gp_10">
                                          {list.status == "reported" ||
                                            list.reviewedBy === props.userId ? (
                                            <p
                                              className="dis_rep_icon"
                                              title="Report"
                                            >
                                              Report
                                            </p>
                                          ) : (
                                            <p
                                              className="rep_icon"
                                              title="Report"
                                              onClick={sendReport}
                                            >
                                              Report
                                            </p>
                                          )}
                                          <div class="div_line"></div>
                                          {list.status == "reported" ||
                                            list.reviewedBy === props.userId ? (
                                            <BsShareFill
                                              className="dis_share_icon"
                                              title="Share"
                                            />
                                          ) : (
                                            <BsShareFill
                                              className="share_icon"
                                              title="Share"
                                              onClick={() => setIsShare(true)}
                                            />
                                          )}
                                        </div>
                                        <div className="mb-0">
                                          <span className="txt_rev fz_14">
                                            Role:
                                          </span>{" "}
                                          <span className="fz_14">
                                            {list.userRole}
                                          </span>
                                        </div>
                                        <div className="mb-0">
                                          <span className="txt_rev fz_14">
                                            Date of Review:
                                          </span>
                                          <span className="fz_14">
                                            {" "}
                                            {moment(list.createdAt).format(
                                              "DD-MMM-YYYY"
                                            )}
                                          </span>
                                        </div>
                                        <div className="d-flex">
                                          {list.reviewQuestion.length != 0 ? (
                                            <label className="font-weight-bold total_fnt mar_9">
                                              {getRevQnsRating(
                                                list.reviewQuestion
                                              )}
                                              &nbsp;
                                            </label>
                                          ) : (
                                            ""
                                          )}
                                          <StarRating
                                            isReview={true}
                                            rating={list.rating}
                                            disabled={true}
                                          />
                                          {list.reviewQuestion &&
                                            list.reviewQuestion.length != 0 && (
                                              <OverlayTrigger
                                                trigger="click"
                                                key={list.id}
                                                placement="bottom"
                                                overlay={
                                                  <Popover
                                                    id={`popover-${list.id}`}
                                                    className="pop_rating"
                                                  >
                                                    <div className="drp_rating"></div>
                                                    {list.reviewQuestion
                                                      .length === 0 ? (
                                                      <div>
                                                        No questions found
                                                      </div>
                                                    ) : (
                                                      list.reviewQuestion.map(
                                                        (item, index) => (
                                                          <Row
                                                            className="d-flex"
                                                            key={index}
                                                          >
                                                            <Col
                                                              sm={1}
                                                              md={1}
                                                              lg={1}
                                                              xl={1}
                                                              className="p-0 prog_span d-flex align-items-center"
                                                            >
                                                              {index + 1}.
                                                            </Col>
                                                            <Col
                                                              sm={5}
                                                              md={5}
                                                              lg={5}
                                                              xl={5}
                                                              className="p-0 d-flex align-items-center"
                                                            >
                                                              <span className="prog_span">
                                                                {item.question}
                                                              </span>
                                                            </Col>
                                                            <Col
                                                              sm={6}
                                                              md={6}
                                                              lg={6}
                                                              xl={6}
                                                              className="p-0"
                                                            >
                                                              <StarRating
                                                                rating={
                                                                  item.rating
                                                                }
                                                                disabled={true}
                                                                customClass="ques-star"
                                                              />
                                                            </Col>
                                                          </Row>
                                                        )
                                                      )
                                                    )}
                                                  </Popover>
                                                }
                                                rootClose
                                              >
                                                <span
                                                  style={{
                                                    marginTop: "5px",
                                                    marginLeft: "5px",
                                                  }}
                                                  className="d-flex align-items-center c_pointer"
                                                  onClick={() =>
                                                    handleArrowClick(
                                                      list.starRating,
                                                      list.averageReviewCount
                                                    )
                                                  }
                                                >
                                                  <BsQuestionDiamond className="clr_gray" />
                                                </span>
                                              </OverlayTrigger>
                                            )}
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                ) : (
                                  <Col lg={8}>
                                    <Row className="d-flex">
                                      <Col
                                        lg={7}
                                        style={{ padding: "0 0 0 6px" }}
                                      >
                                        <div
                                          className={`fz_14 ${expandedCommentIndex === i
                                            ? "show-full"
                                            : ""
                                            }`}
                                          title={list.comment}
                                        >
                                          {list.comment.length >= 30 &&
                                            expandedCommentIndex !== i
                                            ? textWithLinks(
                                              list.comment.substring(0, 30)
                                            )
                                              .map((word, index) => (
                                                <span key={index}>
                                                  {word}{" "}
                                                </span>
                                              ))
                                              .concat("...")
                                            : textWithLinks(list.comment).map(
                                              (word, index) => (
                                                <span key={index}>
                                                  {word}{" "}
                                                </span>
                                              )
                                            )}
                                        </div>

                                        {list.comment.length >= 30 && (
                                          <button
                                            disabled={
                                              list.status == "blocked" ||
                                                list.status == "reported"
                                                ? true
                                                : false
                                            }
                                            onClick={() => expendComment(i)}
                                            className={
                                              list.status == "blocked" ||
                                                list.status == "reported"
                                                ? "read-more-dis"
                                                : "read-more-button"
                                            }
                                          >
                                            {expandedCommentIndex === i
                                              ? "Read less"
                                              : "Read more"}
                                          </button>
                                        )}
                                        <div className="d-flex">
                                          {list.status != "blocked" &&
                                            list.status != "reported"
                                            ? list.reviewComments.length !=
                                            0 && (
                                              <span
                                                className="rply c_pointer"
                                                onClick={() =>
                                                  viewRevComments(
                                                    list.reviewComments,
                                                    list.reviewReplies,
                                                    list.status
                                                  )
                                                }
                                              >
                                                {list.reviewComments
                                                  .length === 1
                                                  ? `${list.reviewComments.length} Reply `
                                                  : `${list.reviewComments.length} Replies `}
                                              </span>
                                            )
                                            : list.reviewComments.length !=
                                            0 && (
                                              <span className="rply_dis ">
                                                {list.reviewComments
                                                  .length === 1
                                                  ? `${list.reviewComments.length} Reply `
                                                  : `${list.reviewComments.length} Replies `}
                                              </span>
                                            )}
                                          {(meData &&
                                            list.status == "reported") ||
                                            (list.status == "blocked" &&
                                              !userIdRev &&
                                              !storedId) ||
                                            list.reviewedBy ===
                                            list.loggedInUserId ||
                                            list.reviewedBy === props.userId ? (
                                            <BiSolidCommentDetail
                                              className="cmt_icon mt-1 clr_gray"
                                              title="Add comment"
                                            />
                                          ) : userIdRev || storedId ? (
                                            ""
                                          ) : (
                                            <BiSolidCommentDetail
                                              className="cmt_icon mt-1 c_pointer"
                                              title="Add comment"
                                              onClick={() =>
                                                togglecomment(list.id)
                                              }
                                            />
                                          )}
                                        </div>
                                      </Col>
                                      <Col lg={5} className="p-0">
                                        <div className="mb-0">
                                          <span className="txt_rev fz_14">
                                            Role:
                                          </span>{" "}
                                          <span className="fz_14">
                                            {list.userRole}
                                          </span>
                                        </div>
                                        <div className="mb-0">
                                          <span className="txt_rev fz_14">
                                            Date of Review:
                                          </span>
                                          <span className="fz_14">
                                            {" "}
                                            {moment(list.createdAt).format(
                                              "DD-MMM-YYYY"
                                            )}
                                          </span>
                                        </div>
                                        <div className="d-flex">
                                          {list.reviewQuestion.length != 0 ? (
                                            <label className="font-weight-bold total_fnt mar_9">
                                              {getRevQnsRating(
                                                list.reviewQuestion
                                              )}
                                              &nbsp;
                                            </label>
                                          ) : (
                                            ""
                                          )}
                                          <StarRating
                                            isReview={true}
                                            rating={list.rating}
                                            disabled={true}
                                          />
                                          {list.reviewQuestion &&
                                            list.reviewQuestion.length != 0 && (
                                              <OverlayTrigger
                                                trigger="click"
                                                key={list.id}
                                                placement="bottom"
                                                overlay={
                                                  <Popover
                                                    id={`popover-${list.id}`}
                                                    className="pop_rating"
                                                  >
                                                    <div className="drp_rating"></div>
                                                    {list.reviewQuestion
                                                      .length === 0 ? (
                                                      <div>
                                                        No questions found
                                                      </div>
                                                    ) : (
                                                      list.reviewQuestion.map(
                                                        (item, index) => (
                                                          <Row
                                                            className="d-flex"
                                                            key={index}
                                                          >
                                                            <Col
                                                              sm={1}
                                                              md={1}
                                                              lg={1}
                                                              xl={1}
                                                              className="cus-col p-0 prog_span d-flex align-items-center"
                                                            >
                                                              {index + 1}.
                                                            </Col>
                                                            <Col
                                                              sm={5}
                                                              md={5}
                                                              lg={5}
                                                              xl={5}
                                                              className="cus-col-ques p-0 d-flex align-items-center"
                                                            >
                                                              <span className="prog_span">
                                                                {item.question}
                                                              </span>
                                                            </Col>
                                                            <Col
                                                              sm={6}
                                                              md={6}
                                                              lg={6}
                                                              xl={6}
                                                              className="cus-col-ratng p-0"
                                                            >
                                                              <StarRating
                                                                rating={
                                                                  item.rating
                                                                }
                                                                disabled={true}
                                                                customClass="ques-star"
                                                              />
                                                            </Col>
                                                          </Row>
                                                        )
                                                      )
                                                    )}
                                                  </Popover>
                                                }
                                                rootClose
                                              >
                                                <span
                                                  style={{
                                                    marginTop: "5px",
                                                    marginLeft: "5px",
                                                  }}
                                                  className="d-flex align-items-center c_pointer"
                                                  onClick={() =>
                                                    handleArrowClick(
                                                      list.starRating,
                                                      list.averageReviewCount
                                                    )
                                                  }
                                                >
                                                  <BsQuestionDiamond className="clr_gray" />
                                                </span>
                                              </OverlayTrigger>
                                            )}
                                        </div>
                                        {userIdRev &&
                                          list.reviewAttachment.length != 0 &&
                                          (list.status != "reported" &&
                                            list.status != "blocked" ? (
                                            <span
                                              title="Attachment"
                                              className="attachmt"
                                              onClick={() =>
                                                viewAttachment(
                                                  list.reviewAttachment
                                                )
                                              }
                                            >
                                              <MdOutlineAttachment />
                                            </span>
                                          ) : (
                                            <MdOutlineAttachment />
                                          ))}
                                      </Col>
                                    </Row>
                                  </Col>
                                )}
                              </Row>
                            </Col>
                            <Col lg={4} className="p-0">
                              {userIdRev && storedId ? (
                                <RatingBar
                                  disabled={true}
                                  liked={list.liked}
                                  likecount={list.likes}
                                  dislikecount={list.dislikes}
                                  handlelike={() => handlelikechange(list.id)}
                                  handledislike={() =>
                                    handledislikechange(list.id)
                                  }
                                  handleRmvlike={() =>
                                    likecntDecrement(list.id)
                                  }
                                  labelVAlue={list.score}
                                />
                              ) : (
                                <RatingBar
                                  disabled={
                                    list.status == "reported" ||
                                      list.status == "blocked" ||
                                      list.reviewedBy === list.loggedInUserId ||
                                      list.reviewedBy === props.userId
                                      ? true
                                      : false
                                  }
                                  liked={list.liked}
                                  likecount={list.likes}
                                  dislikecount={list.dislikes}
                                  handlelike={() => handlelikechange(list.id)}
                                  handledislike={() =>
                                    handledislikechange(list.id)
                                  }
                                  handleRmvlike={() =>
                                    likecntDecrement(list.id)
                                  }
                                  labelVAlue={list.score}
                                />
                              )}
                            </Col>
                          </Row>
                          {currentIndex == list.id ? (
                            <Row>
                              <div className="m-2 position-relative">
                                {cmtError && cmtErrId == list.id ? (
                                  <div className="text-danger fz-12">
                                    {cmtError}
                                  </div>
                                ) : (
                                  ""
                                )}
                                <MentionsInput
                                  style={mentionInputStyle}
                                  className="form-control Cmt_box"
                                  maxLength={288}
                                  onChange={(e) => {
                                    changeCmtText(e.target.value, "parent");
                                  }}
                                  value={comment}
                                  placeholder="type someting..."
                                >
                                  <Mention
                                    style={{ backgroundColor: "#efebe3" }}
                                    trigger="@"
                                    data={users}
                                  />
                                </MentionsInput>
                                {/* <textarea className="form-control Cmt_box" placeholder="type someting..." onChange={(e) => setComment(e.target.value)} value={comment}/> */}
                                <button disabled={postBtnDisable}
                                  className="btn btn-primary cmt_btn"
                                  onClick={() =>
                                    postComment(list.id, 0, "parent")
                                  }
                                >
                                  Post
                                </button>
                              </div>
                            </Row>
                          ) : (
                            ""
                          )}
                        </Card>
                      ))}
                  </div>
                </Col>
              </Row>
            </Col>
          </Card>
        </div>
        <Modal size="sm" show={isShare} onHide={() => setIsShare(false)}>
          <Modal.Header style={{ padding: "15px 0px!important" }}>
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "15px", fontWeight: 600 }}
            >
              Share
            </Modal.Title>
            <span
              onClick={() => {
                setIsShare(false);
              }}
              title="Close"
              style={{ cursor: "pointer" }}
            >
              <AiOutlineClose />
            </span>
          </Modal.Header>
          <Modal.Body className="text-center p-2">
            {meData && (
              <>
                <FacebookShareButton
                  url={
                    process.env.REACT_APP_URL +
                    "/shareview" +
                    "/" +
                    meData.reviewerName +
                    "/" +
                    meData.rating
                  }
                  className="m-2"
                >
                  <FacebookIcon size={32} />
                </FacebookShareButton>
                <TwitterShareButton
                  url={
                    process.env.REACT_APP_URL +
                    "/shareview" +
                    "/" +
                    meData.reviewerName +
                    "/" +
                    meData.rating
                  }
                  title="tiipstr"
                  quote={getSocialMediaShareInfo}
                  hashtag={getSocialMediaHashTag}
                  className="m-2"
                >
                  <TwitterIcon size={32} />
                </TwitterShareButton>
                <LinkedinShareButton
                  url={
                    process.env.REACT_APP_URL +
                    "/shareview" +
                    "/" +
                    meData.reviewerName +
                    "/" +
                    meData.rating
                  }
                  title="tiipstr"
                  quote={getSocialMediaShareInfo}
                  hashtag={getSocialMediaHashTag}
                  className="m-2"
                >
                  <LinkedinIcon size={32} />
                </LinkedinShareButton>
              </>
            )}
          </Modal.Body>
        </Modal>
        <Modal
          size="md"
          className="report_modal"
          show={showReport}
          onHide={() => setShowReport(false)}
        >
          <Modal.Header
            className="border_rst__btm"
            style={{ padding: "15px 0px!important" }}
          >
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "15px", fontWeight: 600 }}
            >
              <h6 className="font-weight-bold">Submit a report</h6>
            </Modal.Title>
            <span
              onClick={() => {
                setShowReport(false);
              }}
              title="Close"
              style={{ cursor: "pointer" }}
            >
              <AiOutlineClose />
            </span>
          </Modal.Header>
          <Modal.Body style={{ fontSize: "14px" }}>
            <textarea
              className="form-control Comment_sty h_25"
              placeholder="Please add details about your report…"
              onChange={handleReport}
            ></textarea>
            {reportCommentErr && (
              <div className="text-danger">{reportCommentErr}</div>
            )}
            <Col sm={12} md={12} lg={12} xl={12} className="text-center mt-2 p-0">
              <Button
                className="btn-warning font-weight-bold w-100"
                onClick={submitReport}
              >
                Submit
              </Button>
            </Col>
          </Modal.Body>
        </Modal>
        <Modal
          size="md"
          className="report_modal"
          show={showReply}
          onHide={() => setShowReply(false)}
        >
          <Modal.Header
            className="border_rst__btm"
            style={{ padding: "15px 0px!important" }}
          >
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "15px", fontWeight: 600 }}
            >
              <h6 className="font-weight-bold">Review Comments</h6>
            </Modal.Title>
            <span
              onClick={() => {
                setShowReply(false);
              }}
              title="Close"
              style={{ cursor: "pointer" }}
            >
              <AiOutlineClose />
            </span>
          </Modal.Header>
          <Modal.Body style={{ fontSize: "14px" }}>
            {revCmnt &&
              revCmnt.map((data) => (
                <Row>
                  <Col
                    className={`revCmtPop pb-0 ${showChildReply && replies.length != 0 ? "revcmt_bg" : ""
                      }`}
                  >
                    <div className="d-flex">
                      <div>
                        <img
                          className="brd_rad h_w_25"
                          src={
                            data.userImagePath ? data.userImagePath : profile
                          }
                        />
                      </div>
                      <div
                        className="d-flex"
                        style={{ marginLeft: "8px", alignItems: "center" }}
                      >
                        {data.userName}
                        <span className="dat_icn">
                          <GoDotFill />
                        </span>{" "}
                        <div style={{ fontSize: "11px", color: "#959595" }}>
                          {" "}
                          {moment
                            .utc(data.createdAt)
                            .local()
                            .startOf("seconds")
                            .fromNow()}
                        </div>
                      </div>
                    </div>
                    <div className="ctTxt">
                      {textWithLinks(data.commentText).map((word, index) => (
                        <span key={index}>{word} </span>
                      ))}
                    </div>
                    <div class="send d-flex gap_20 mb-2 mt-2">
                      <RatingBar
                        disabled={
                          (meData && ReviewSts == "reported") ||
                            ReviewSts == "reported" ||
                            data.userId === data.loggedInUserId ||
                            data.userId === props.userId
                            ? true
                            : false
                        }
                        msgreply={true}
                        liked={data.liked}
                        likecount={data.likes}
                        dislikecount={data.dislikes}
                        handlelike={() =>
                          handlerplylikechange(data.id, data.reviewId)
                        }
                        handledislike={() =>
                          handlerplydislikechange(data.id, data.reviewId)
                        }
                        handleRmvlike={() =>
                          rplylikecntDecrement(data.id, data.reviewId)
                        }
                        labelVAlue={data.score}
                      />
                      {(meData &&
                        ReviewSts == "reported" &&
                        !userIdRev &&
                        !storedId) ||
                        ReviewSts == "reported" ||
                        data.userId === data.loggedInUserId ||
                        data.userId === props.userId ? (
                        <BiSolidCommentDetail
                          className="cmt_icon clr_gray"
                          title="Add comment"
                        />
                      ) : (
                        <BiSolidCommentDetail
                          className="cmt_icon c_pointer"
                          title="Add comment"
                          onClick={() => togglecomment(data.id)}
                        />
                      )}
                      <Row>
                        {replies.filter(
                          (obj) =>
                            obj.id == data.id || obj.parentCommentId == data.id
                        ).length != 0 && (
                            <span
                              className="rply c_pointer"
                              onClick={() =>
                                viewChildComments(replies, data.id, data.reviewId)
                              }
                            >
                              {replies.filter(
                                (obj) =>
                                  obj.id == data.id ||
                                  obj.parentCommentId == data.id
                              ).length === 1
                                ? `${replies.filter(
                                  (obj) =>
                                    obj.id == data.id ||
                                    obj.parentCommentId == data.id
                                ).length
                                } Reply `
                                : `${replies.filter(
                                  (obj) =>
                                    obj.id == data.id ||
                                    obj.parentCommentId == data.id
                                ).length
                                } Replies `}
                              <span>
                                {childcurrentIndex == data.id ? (
                                  <IoMdArrowDropup />
                                ) : (
                                  <IoMdArrowDropdown />
                                )}
                              </span>
                            </span>
                          )}
                      </Row>
                    </div>
                    {currentIndex == data.id ? (
                      <Row>
                        <div className="position-relative p-0">
                          {cmtError && cmtErrId == data.id ? (
                            <div className="text-danger fz-12 ml-2">
                              {cmtError}
                            </div>
                          ) : (
                            ""
                          )}
                          <MentionsInput
                            style={mentionInputStyle}
                            className="form-control Cmt_box"
                            maxLength={288}
                            onChange={(e) => {
                              changeCmtText(e.target.value, "child");
                            }}
                            value={childComment}
                            placeholder="type someting..."
                          >
                            <Mention
                              style={{ backgroundColor: "#efebe3" }}
                              trigger="@"
                              data={users}
                            />
                          </MentionsInput>
                          {/* <textarea className="form-control Cmt_box" placeholder="type someting..." onChange={(e) => setchildComment(e.target.value)} value={childComment}/> */}
                          <button disabled={postBtnDisable}
                            className="btn btn-primary cmt_btn"
                            onClick={() =>
                              postComment(data.id, data.reviewId, "child")
                            }
                          >
                            Post
                          </button>
                        </div>
                      </Row>
                    ) : (
                      ""
                    )}
                    {childcurrentIndex == data.id && replies.length != 0
                      ? replies.map((item, index) =>
                        item.id == data.id ||
                          item.parentCommentId == data.id ? (
                          <Row>
                            <Col className="revchdcmt pb-0">
                              <div className="d-flex">
                                <div>
                                  <img
                                    className="brd_rad h_w_25"
                                    src={
                                      item.replyUserImagePath
                                        ? item.replyUserImagePath
                                        : profile
                                    }
                                  />
                                </div>
                                <div
                                  className="d-flex"
                                  style={{
                                    marginLeft: "8px",
                                    alignItems: "center",
                                  }}
                                >
                                  {item.replyUserName}
                                  <span className="dat_icn">
                                    <GoDotFill />
                                  </span>{" "}
                                  <div
                                    style={{
                                      fontSize: "11px",
                                      color: "#959595",
                                    }}
                                  >
                                    {" "}
                                    {moment
                                      .utc(item.createdAt)
                                      .local()
                                      .startOf("seconds")
                                      .fromNow()}
                                  </div>
                                </div>
                              </div>
                              <div className="ctTxt">
                                <span className="rep_men">
                                  {"@" + item.userName + " "}
                                </span>
                                {textWithLinks(item.replyCommentText).map(
                                  (word, index) => (
                                    <span key={index}>{word} </span>
                                  )
                                )}
                              </div>
                              <div class="send d-flex gap_20 mb-2 mt-2">
                                <RatingBar
                                  disabled={
                                    (meData && ReviewSts == "reported") ||
                                      ReviewSts == "reported" ||
                                      item.replyUserId ===
                                      item.loggedInUserId ||
                                      item.replyUserId === props.userId
                                      ? true
                                      : false
                                  }
                                  msgreply={true}
                                  liked={item.liked}
                                  likecount={item.likes}
                                  dislikecount={item.dislikes}
                                  handlelike={() =>
                                    handlerplylikechange(
                                      item.replyCommentId,
                                      data.reviewId
                                    )
                                  }
                                  handledislike={() =>
                                    handlerplydislikechange(
                                      item.replyCommentId,
                                      data.reviewId
                                    )
                                  }
                                  handleRmvlike={() =>
                                    rplylikecntDecrement(
                                      item.replyCommentId,
                                      data.reviewId
                                    )
                                  }
                                  labelVAlue={item.score}
                                />
                                {(meData &&
                                  ReviewSts == "reported" &&
                                  !userIdRev &&
                                  !storedId) ||
                                  ReviewSts == "reported" ||
                                  item.replyUserId === item.loggedInUserId ||
                                  item.replyUserId === props.userId ? (
                                  <BiSolidCommentDetail
                                    className="cmt_icon clr_gray"
                                    title="Add comment"
                                  />
                                ) : (
                                  <BiSolidCommentDetail
                                    className="cmt_icon c_pointer"
                                    title="Add comment"
                                    onClick={() =>
                                      togglechildcomment(item.replyCommentId)
                                    }
                                  />
                                )}
                              </div>
                              {childrepplycurrentIndex ==
                                item.replyCommentId ? (
                                <Row>
                                  <div className="position-relative p-0">
                                    {cmtError &&
                                      cmtErrId == item.replyCommentId ? (
                                      <div className="text-danger ml-2 fz-12">
                                        {cmtError}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <MentionsInput
                                      style={mentionInputStyle}
                                      className="form-control Cmt_box"
                                      maxLength={288}
                                      onChange={(e) => {
                                        changeCmtText(
                                          e.target.value,
                                          "child"
                                        );
                                      }}
                                      value={childComment}
                                      placeholder="type someting..."
                                    >
                                      <Mention
                                        style={{ backgroundColor: "#efebe3" }}
                                        trigger="@"
                                        data={users}
                                      />
                                    </MentionsInput>
                                    {/* <textarea className="form-control Cmt_box" placeholder="type someting..." onChange={(e) => setchildComment(e.target.value)} value={childComment}/> */}
                                    <button disabled={postBtnDisable}
                                      className="btn btn-primary cmt_btn"
                                      onClick={() =>
                                        postComment(
                                          item.replyCommentId,
                                          data.reviewId,
                                          "child"
                                        )
                                      }
                                    >
                                      Post
                                    </button>
                                  </div>
                                </Row>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )
                      )
                      : ""}
                  </Col>
                </Row>
              ))}
          </Modal.Body>
        </Modal>

        {/* Attachment popup */}

        <Modal
          size="md"
          className="report_modal"
          show={showAttachment}
          onHide={() => setShowAttachment(false)}
        >
          <Modal.Header
            className="border_rst__btm"
            style={{ padding: "15px 0px!important" }}
          >
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "15px", fontWeight: 600 }}
            >
              <h6 className="font-weight-bold">Attachments</h6>
            </Modal.Title>
            <span
              onClick={() => {
                setShowAttachment(false);
              }}
              title="Close"
              style={{ cursor: "pointer" }}
            >
              <AiOutlineClose />
            </span>
          </Modal.Header>
          <Modal.Body className="d-flex overflow-auto">
            {attachment &&
              attachment.map((file) => (
                <div className="col-3 col-md-3 col-lg-3 mrt_5">
                  {checkExt(file) ? (
                    <img
                      width="70"
                      height="70"
                      src={file.filePath ? file.filePath : ""}
                      className="br_5px border_att"
                    />
                  ) : (
                    <img
                      width="70"
                      height="70"
                      src={fileImg}
                      className="br_5px border_att"
                    />
                  )}
                  <div className="attch_imgview" title={file.fileName}>
                    <a
                      title={file.fileName}
                      href={file.filePath}
                      className="color-black"
                      style={{ fontSize: "13px" }}
                      target="_blank"
                    >
                      {" "}
                      {file.fileName.substring(0, 9) + "..."}
                    </a>
                  </div>
                </div>
              ))}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    userType: state.user.userType,
    profileId: state.user.profileId,
  };
};
export default connect(mapStateToProps)(FollowUser);
