import { Card, Col, Row, Button } from "react-bootstrap";
import profile from "../assets/images/profile.jpg";
export const commonUtils = {
  getBannerName: (pathname) => {
    const path = pathname.split("/");
    if (path[2] !== undefined) {
      if (path[2] === "finduser") return "Add User";
      else if (path[2] == "admin_profile") return "Profile";
      else if (path[2] == "user_roles") return "Dashboard";
      else if (path[2] === "viewuser") return "View User";
      else if (path[1] === "inbox") return "Inbox";
      else if (path[2] == "support_profile") return "Profile";
      else if (path[2] == "report") return "Report";
      else return path[2].charAt(0).toUpperCase() + path[2].slice(1);
    } else if (path[1] === "dashboard") {
      return "Dashboard";
    } else if (path[2] === "report") {
      return "Dashboard";
    } else {
      return path[1].charAt(0).toUpperCase() + path[1].slice(1);
    }
  },
  changeCmtText: (
    value,
    type,
    setmentionuserIds,
    setComment,
    setchildComment
  ) => {
    var words = value.split(/\s(?![^\[]*])/);
    var contents = words.map(function (word, i) {
      var separator = i < words.length - 1 ? " " : "";
      return word + separator;
    });
    if (contents.length !== 0) {
      var filtercontents = contents.filter((name) => name.startsWith("@"));
      var missing = filtercontents.map(function (o1) {
        if (o1 !== "@" && o1 && o1.includes("](")) {
          var mention = o1.split("](");
          var men_id = mention[1].replace(")", "");
          if (men_id.includes("-")) {
            var usrId = men_id.split("-");
            var userId = usrId[0];
            var ProfId = usrId[1];
            return { userId: +userId, professionalId: +ProfId };
          } else if (men_id.includes("&")) {
            var usrId = men_id.split("&");
            var userId = usrId[0];
            var BusnId = usrId[1];
            return { userId: +userId, businessId: +BusnId };
          } else return { userId: +men_id, professionalId: null };
        }
      });
      if (missing !== undefined) {
        const filteredMissing = missing.filter((item) => item !== undefined);
        const uniqueData = Array.from(
          new Set(filteredMissing.map(JSON.stringify))
        ).map(JSON.parse);
        setmentionuserIds(uniqueData);
      }
    }
    let cmtText = contents.reduce(
      (accumulator, item) => (accumulator += item),
      ""
    );
    if (type === "parent") {
      if (cmtText.length > 288) {
        var limit_char = cmtText.substring(0, 288);
        setComment(limit_char);
      } else setComment(cmtText);
    } else {
      if (cmtText.length > 288) {
        var limit_char = cmtText.substring(0, 288);
        setchildComment(limit_char);
      } else setchildComment(cmtText);
    }
  },
  changeSimpleCmtText: (
    value,
    setCommentError,
    setmentionuserIds,
    setComment
  ) => {
    if (value) {
      setCommentError("");
    }
    var words = value.split(/\s(?![^\[]*])/);
    var contents = words.map(function (word, i) {
      var separator = i < words.length - 1 ? " " : "";
      return word + separator;
    });
    if (contents.length !== 0) {
      var filtercontents = contents.filter((name) => name.startsWith("@"));
      var missing = filtercontents.map(function (o1) {
        if (o1 !== "@" && o1 && o1.includes("](")) {
          var mention = o1.split("](");
          var men_id = mention[1].replace(")", "");
          if (men_id.includes("-")) {
            var usrId = men_id.split("-");
            var userId = usrId[0];
            var ProfId = usrId[1];
            return { userId: +userId, professionalId: +ProfId };
          } else if (men_id.includes("&")) {
            var usrId = men_id.split("&");
            var userId = usrId[0];
            var BusnId = usrId[1];
            return { userId: +userId, businessId: +BusnId };
          } else return { userId: +men_id, professionalId: null };
        }
      });
      if (missing !== undefined) {
        const filteredMissing = missing.filter((item) => item !== undefined);
        const uniqueData = Array.from(
          new Set(filteredMissing.map(JSON.stringify))
        ).map(JSON.parse);
        setmentionuserIds(uniqueData);
      }
    }
    let cmtText = contents.reduce(
      (accumulator, item) => (accumulator += item),
      ""
    );
    if (cmtText.length > 288) {
      var limit_char = cmtText.substring(0, 288);
      setComment(limit_char);
    } else setComment(cmtText);
  },
  LeftDiv: () => {
    return (
      <>
        <div className="mt-4 mb-3">
          <h5 className="fz_18">See what they’re saying…</h5>
        </div>
        <div className="brand_logo"></div>
        <div className="tiipstr_logo"></div>
        <div className="d-flex align-items-center">
          <span className="mb-0 fz_13 font-weight-400 opacity-9">
            Powered by&nbsp;
          </span>
          <p className="mb-0 fz_15 font-weight-700">Smart Score Technology</p>
        </div>
      </>
    );
  },
  ratingTooltip: (currentUsers) => {
    return (
      <>
        {currentUsers.length > 0 && (
          <div className="tooltiptext">
            <div>
              {currentUsers.map((user, idx) => (
                <Row key={idx} className="align-items-center pt-1 pb-1">
                  <Col sm={12} md={8} lg={8} className="p-0">
                    <img
                      className="rounded-circle"
                      src={user.imagePath ? user.imagePath : profile}
                      alt="img"
                      width={30}
                      height={30}
                    />
                    &nbsp;
                    <span>{user.name}</span>
                  </Col>
                  <Col sm={12} md={4} lg={4} className="p-0">
                    <span>{user.rating.toFixed(1)}</span>
                  </Col>
                </Row>
              ))}
            </div>
          </div>
        )}
      </>
    );
  },


  convertDataToChartData: (data, aggregationType, type) => {

    function getISOWeek(date) {
      const firstThursday = new Date(date.getFullYear(), 0, 1 + (4 - new Date(date.getFullYear(), 0, 1).getDay()) % 7);
      return Math.ceil((((date - firstThursday) / 86400000) + 1) / 7);
    }
    const labels = [];
    const ratings = [];
    const reviewCounts = [];
    const ratingsMap = new Map();
    const currentDate = new Date();
    const reviewCountsMap = new Map();
    if (aggregationType === "monthly"){
    data.forEach((item) => {
        const key = `${item.year}-${String(item.month).padStart(2, '0')}`;
        if (!ratingsMap.has(key)) {
            ratingsMap.set(key, 0);
            reviewCountsMap.set(key, 0);
        }
        ratingsMap.set(key, ratingsMap.get(key) + item.rating);
        reviewCountsMap.set(key, reviewCountsMap.get(key) + item.reviewCount);
    });
    const currentDate = new Date();
    const months = [];
    for (let i = 0; i < 12; i++) {
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i);
        months.push(date);
    }
    months.reverse();
    months.forEach(currentDate => {
        const year = currentDate.getFullYear();
        const monthIndex = currentDate.getMonth() + 1; 
        const monthLabel = currentDate.toLocaleString("default", { month: "short" });
        const key = `${year}-${String(monthIndex).padStart(2, '0')}`;
        if (!labels.includes(monthLabel)) {
            labels.push(monthLabel);
        }
        const totalRating = ratingsMap.get(key) || 0;  
        const totalReviews = reviewCountsMap.get(key) || 0;  
        let avgRating = totalReviews > 0 ? totalRating / totalReviews : 0;
        let reviewCount = totalReviews;
        if (totalReviews > 0) {
            ratings.push(avgRating > 5.0 ? 5.0 : avgRating.toFixed(1));
            reviewCounts.push(reviewCount);
        } else {
            ratings.push(0.0);
            reviewCounts.push(0);
        }
    });
    }  
    else if (aggregationType === "yearly") {
      data.forEach((item) => {
        const key = `${item.year}`;
        if (!ratingsMap.has(key)) {
          ratingsMap.set(key, 0);
          reviewCountsMap.set(key, 0);
        }
        ratingsMap.set(key, ratingsMap.get(key) + item.rating);
        reviewCountsMap.set(key, reviewCountsMap.get(key) + item.reviewCount);
      });

      const years = [];
      for (let i = 2; i >= 0; i--) {
        years.push(currentDate.getFullYear() - i);
      }

      let previousAvgRating = 0;
      let previousReviewCount = 0;
      for (const year of years) {
        const key = `${year}`;
        labels.push(year.toString());
        const totalRating = ratingsMap.get(key) || 0;
        const totalReviews = reviewCountsMap.get(key) || 0;
        if (type == "CS") {
          var avgRating = totalRating;
        } else {
          var avgRating = totalReviews > 0 ? totalRating / totalReviews : 0;
        }
        let reviewCount = totalReviews;
          previousAvgRating = avgRating;
          previousReviewCount = reviewCount;
        ratings.push(avgRating > 5.0 ? 5.0 : avgRating.toFixed(1));
        reviewCounts.push(reviewCount);
      }
    } else if (aggregationType === "daily") {
      const ratingsMap = new Map();
      const reviewCountsMap = new Map();
      data.forEach((item) => {
        const key = `${item.year}-${item.month}-${item.day}`;
        if (!ratingsMap.has(key)) {
          ratingsMap.set(key, 0);
          reviewCountsMap.set(key, 0);
        }
        ratingsMap.set(key, ratingsMap.get(key) + item.rating);
        reviewCountsMap.set(key, reviewCountsMap.get(key) + item.reviewCount);
      });
      const days = [];
      for (let i = 0; i < 7; i++) {
        const date = new Date();
        date.setDate(date.getDate() - i);
        days.push(date);
      }
      days.reverse();
      for (const date of days) {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const key = `${year}-${month}-${day}`;
        labels.push(day.toString());
        const ratingSum = ratingsMap.get(key) || 0;
        const reviewCount = reviewCountsMap.get(key) || 0;
        if (reviewCount > 0) {
          const averageRating = Math.min(5.0, ratingSum);
          ratings.push(averageRating); 
        } else {
          ratings.push(0);
        }
        if (reviewCount > 0) {
          reviewCounts.push(reviewCount);
        } else {
          reviewCounts.push(0);
        }
      }
    }
    else if (aggregationType === "weekly") {
      data.forEach((item) => {
        const key = `${item.year}-${item.week}`;
        if (!ratingsMap.has(key)) {
          ratingsMap.set(key, { totalRating: 0, totalCount: 0 });
        }
        ratingsMap.get(key).totalRating += item.rating;
        ratingsMap.get(key).totalCount += item.reviewCount;
      });

      const currentYear = currentDate.getFullYear();
      const currentWeek = getISOWeek(currentDate);
      const weeksToShow = 4;
      const ratingsArray = new Array(weeksToShow).fill('0');
      const reviewCountsArray = new Array(weeksToShow).fill('0');
      labels.length = 0;

      let mostRecentYear = 0;
      let mostRecentWeek = 0;
      data.forEach((item) => {
        if (item.year > mostRecentYear || (item.year === mostRecentYear && item.week > mostRecentWeek)) {
          mostRecentYear = item.year;
          mostRecentWeek = item.week;
        }
      });

      for (let weekOffset = 0; weekOffset < weeksToShow; weekOffset++) {
        const week = currentWeek - (weeksToShow - 1 - weekOffset);
        const adjustedWeek = week <= 0 ? 52 + week : week;
        const year = week <= 0 ? currentYear - 1 : currentYear;
        const key = `${year}-${adjustedWeek}`;
        const { totalRating, totalCount } = ratingsMap.get(key) || { totalRating: 0, totalCount: 0 };
        let avgRating = totalCount > 0 ? (totalRating / totalCount).toFixed(1) : '0';
        let reviewCount = totalCount;
        ratingsArray[weekOffset] = avgRating;
        reviewCountsArray[weekOffset] = reviewCount.toString();
        labels.push(`${weekOffset + 1} Week`);
      }
      const specificKey = `${mostRecentYear}-${mostRecentWeek}`;
      const specificIndex = weeksToShow - 1;
      if (ratingsMap.has(specificKey)) {
        const specificData = ratingsMap.get(specificKey);
        ratingsArray[specificIndex] = (specificData.totalRating / specificData.totalCount).toFixed(1);
        reviewCountsArray[specificIndex] = specificData.totalCount.toString();
      }
      for (let i = 1; i < ratingsArray.length; i++) {
        if (ratingsArray[i] === '0') {
          ratingsArray[i] = ratingsArray[i - 1] !== '0' ? ratingsArray[i - 1] : ratingsArray[i];
        }
      }
      for (let i = 1; i < reviewCountsArray.length; i++) {
        if (reviewCountsArray[i] === '0') {
          reviewCountsArray[i] = reviewCountsArray[i - 1] !== '0' ? reviewCountsArray[i - 1] : reviewCountsArray[i];
        }
      }
      ratings.push(...ratingsArray);
      reviewCounts.push(...reviewCountsArray);
    }
    return { labels, ratings, reviewCounts };
  },
};
